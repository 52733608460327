import React, { Component } from 'react';

class Notice extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return this.showPage();
    }

    showPage() {
        return (
            <div className='mt20 container'>
                <div className='row'>
                    <div className='mb20 cPointer'>
                        <a className='blueButton' href = '/'>Home</a>
                    </div>
                    <h2 className="heading">Notice For Japanese Investors</h2>

                    <p className='descPara2'>
                        Tor Investment Management (Hong Kong) Limited acting in its capacity as a delegate of Tor Asia Credit Fund GP Ltd. and TACOF III GP LLC, shall make available the 
                        Form 20-2 and Form 21-3 to Japan public. Any person that wishes to receive a copy of such documents, please kindly contact the following e-mail address: 
                        <b>info@torinvestment.com</b>
                    </p>

                    <h1>Important</h1>

                    <p className='descPara2'>
                        This material is provided to you for informational purposes only and does not constitute the provision of investment advice. In addition, the information 
                        contained in this material does not constitute an offer to sell nor a solicitation of any offer to buy and is not intended to be, and should not be construed 
                        as, an offer to sell nor a solicitation of an offer to buy any type of securities, financial products or other services offered by Tor Investment Management 
                        (Hong Kong) Limited and its affiliates to any person in Japan to whom such offer would be unlawful under the applicable laws of Japan.
                    </p>

                    <p className='descPara2Japanese'>
                        Tor Investment Management (Hong Kong) Limited はTor Asia Credit Fund GP Ltd.及びTACOF III GP LLCの委託を受けて、
                        別紙様式第20号の2及び第21号の3を公衆の縦覧に供するための手続きを行います。ご希望の方は下記アドレスまでご連絡ください。<b>info@torinvestment.com</b>
                    </p>

                    <h1>重要</h1>

                    <p className='descPara2Japanese'>
                        ご依頼に基づき送付させていただく資料（以下「送付資料」といいます。）は情報提供のみを目的としており、投資助言を構成するものではありません。また、
                        送付資料に含まれる情報は販売の申し出もしくは購入の申込みの勧誘を行うものではなく、Tor Investment Management (Hong Kong) Limited 
                        もしくはその関連会社から日本に居住されている方に対して、日本の適用法令に違反することとなる証券、
                        金融商品その他のサービスの販売の申し出もしくは購入の申込みの勧誘を意図するものでも、又はそのように解釈されるものでもありません。
                    </p>
                </div>
            </div>
        );
    }
}

export default Notice;
