import React, { Component } from 'react';
import history from './history/history';
import NavRoutes from './component/nav_routes/nav_routes';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import { Link } from 'react-scroll';

const logo = require('./images/TOR_LOGO_430_2965.png');

// const organizationSchema = {
//   "@context": "http://schema.org",
//   "@type": "Organization",
//   "name": "Tor Investment Management",
//   "url": "https://www.torinvestment.com/",
//   "members": [
//     {
//       "@type": "Person",
//       "name": "Patrik Edsparr",
//       "jobTitle": "Chief Investment Officer, Co-Founder",
//       "url": "https://www.torinvestment.com/"
//     },
//     {
//       "@type": "Person",
//       "name": "Christopher Mikosh",
//       "jobTitle": "Portfolio Manager, Co-Founder",
//       "url": "https://www.torinvestment.com/"
//     },
//     {
//       "@type": "Person",
//       "name": "James Sweeney",
//       "jobTitle": "Chief Operating Officer",
//       "url": "https://www.torinvestment.com/"
//     }
//   ]
// }



class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navbarToggler: 'navbar-toggler',
      ariaExpanded: 'false',
      navbarCollapse: 'navbar-collapse collapse',
      currentPage: window.location.pathname
    }

    this.handleGoto = this.handleGoto.bind(this);
    this.renderApp = this.renderApp.bind(this);
    this.handleNavBarToggler = this.handleNavBarToggler.bind(this);
  }

  handleGoto(page) {
    this.setState({ currentPage: page });
    history.push(page);
    window.location.pathname = page;
  }

  render() {
    return this.renderApp();
  }

  handleNavBarToggler() {
    this.setState({
      navbarToggler: this.state.navbarToggler === 'navbar-toggler' ? 'navbar-toggler collapsed' : 'navbar-toggler',
      ariaExpanded: this.state.ariaExpanded === 'false' ? 'true' : 'false',
      navbarCollapse: this.state.navbarCollapse === 'navbar-collapse collapse' ? 'navbar-collapse collapse show' : 'navbar-collapse collapse'
    });
  }

  renderApp() {
    const { navbarToggler, ariaExpanded, navbarCollapse } = this.state;
    return (
      <div style={history.location.pathname === '/' 
        ? { gridTemplateRows: 'auto 1fr', display: 'grid', height: '100%', width: '100%', borderTop: "solid 1px white", position: 'relative' } 
        : { gridTemplateRows: '1fr auto', display: 'grid', height: '100%', width: '100%', borderTop: "solid 1px white", position: 'relative' } 
      }>
        {/* <script type="application/ld+json">
          {JSON.stringify(organizationSchema)}
        </script> */}
        <div style = {history.location.pathname === '/' ? {} : {display: "none"} }>
          <nav className="navbar navbar-expand-lg navbar-light fixed-top bgLight bgBlueBlur">
            <a className="navbar-brand" href="#" style={{ marginLeft: '10px' }}>
              <img src={logo} height="40" alt="" />
            </a>
            <button className={navbarToggler} type="button" data-toggle="collapse" data-target="#navbarColor01" aria-controls="navbarColor01"
              aria-expanded={ariaExpanded} aria-label="Toggle navigation" onClick={this.handleNavBarToggler}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className={navbarCollapse} id="navbarColor01">
              <div className='container'>
                <div className='row'>
                  <div className="col-md taCenter m5">
                    <a className="nav-link navUpdate" href="#">
                      <Link activeClass='active' smooth spy to='home'>
                        Home
                      </Link>
                    </a>
                  </div>
                  <div className="col-md taCenter m5">
                    <a className="nav-link navUpdate" href="#">
                      <Link activeClass='active' smooth spy to='about'>
                        About
                      </Link>
                    </a>
                  </div>
                  <div className="col-md taCenter m5">
                    <a className="nav-link navUpdate" href="#">
                      <Link activeClass='active' smooth spy to='approach'>
                        Approach
                      </Link>
                    </a>
                  </div>
                  <div className="col-md taCenter m5">
                    <a className="nav-link navUpdate" href="#">
                      <Link activeClass='active' smooth spy to='leadership'>
                        Leadership
                      </Link>
                    </a>
                  </div>
                  <div className="col-md taCenter m5">
                    <a className="nav-link navUpdate" href="#">
                      <Link activeClass='active' smooth spy to='contact'>
                        Contact
                      </Link>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div>
          <NavRoutes history={history}></NavRoutes>
        </div>
        <div className='blueG'>
          <div style={{ display: "flex", textAlign: "center" }}>
            <p style={{ margin: "0 auto" }}>© Tor Investment Management 2023</p>
          </div>
          <div style={{ display: "flex", flexDirection: "initial", textAlign: "left", marginLeft: "30px", marginTop: "10px", marginBottom: "10px" }}>
            <p style={{ margin: "0 auto" }}>
              <a style={{ cursor: "pointer", color: "white" }} href = "/disclaimer">General Disclaimer & Terms of Use</a>
              &nbsp; | &nbsp;
              <a style={{ cursor: "pointer", color: "white" }} href = "/privacy">Privacy Policy</a>
              &nbsp; | &nbsp;
              <a style={{ cursor: "pointer", color: "white" }} href = "/notice">Notice for Japanese Investors</a>
            </p>
          </div>
          <div style={{ display: "flex", flexDirection: "initial", textAlign: "left", marginLeft: "30px", marginTop: "20px", marginBottom: "5px" }}>
            <p style={{ margin: "0 auto" }}>
              AUM and statistics mentioned above are as of April 1, 2024.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default App;