import React, { Component } from 'react';

class Privacy extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return this.showPage();
    }

    showPage() {
        return (
            <div className='mt20 container pPolicy'>
                <div className='row'>
                    <div className='mb20 cPointer'>
                        <a className='blueButton' href = '/'>Home</a>
                    </div>
                    <h2 className="heading">Privacy Policy</h2>

                    <p className='descPara2'>
                        By using this website (the "Site") or accessing any information in or through the Site, you agree to be bound by these privacy policies, as amended from time to 
                        time (the "Privacy Policy"). If you do not agree to this Privacy Policy, do not use this Site.
                    </p>

                    <ol>
                        <li>
                            <p className='descPara2'>
                                Tor Investment Management (Hong Kong) Limited ("Tor Hong Kong"), together with Tor Entities (defined below, Tor Hong Kong, Tor Entities and their 
                                successors, novatees, and assignees, collectively “Tor”) reserve the right to collect information (including by using cookies) about you while you 
                                use or otherwise access the Site including, without limitation, the information that you access in or through the Site, your URL clickstream to, 
                                through and from the Site, your browser, operating system, Internet provider, connection speed and the internet protocol address through which your 
                                computer accesses the Internet. 
                            </p>
                            <ol type='a'>
                                <li>
                                    <p className='descPara2'>
                                        Your provision of such information described in this Paragraph is mandatory and a condition to your use of the Site. 
                                    </p>
                                </li>
                                <li>
                                    <p className='descPara2'>
                                        Tor may use the information described in this Paragraph for the purposes of:
                                    </p>

                                    <ol type = 'i'>
                                        <li>
                                            <p className='descPara2'>
                                                improving the functionality and user experience of the Site, 
                                            </p>
                                        </li>
                                        <li>
                                            <p className='descPara2'>
                                                maintaining security with respect to the Site and its components and 
                                            </p>
                                        </li>
                                        <li>
                                            <p className='descPara2'>
                                                complying with applicable laws, regulations and other legal requirements, including the Personal Data (Privacy) Ordinance 
                                                (Cap. 486, Laws of Hong Kong) (collectively, "Laws"). 
                                            </p>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p className='descPara2'>
                                        You acknowledge, agree, permit, and authorize that Tor may disclose such information in respect of a purpose or a directly related purpose 
                                        for which the data was provided with:
                                    </p>

                                    <ol type = 'i'>
                                        <li>
                                            <p className='descPara2'>
                                                all shareholders, group entities and affiliates of Tor Hong Kong, and all entities and investment funds directly or indirectly 
                                                managed or otherwise directed by Tor Hong Kong (collectively, "Tor Entities"); 
                                            </p>
                                        </li>
                                        <li>
                                            <p className='descPara2'>
                                                auditors, attorneys, consultants, contractors and other service providers and persons or entities acting on behalf of Tor, and their 
                                                directors, officers, employees, representatives, agents, or delegates; 
                                            </p>
                                        </li>
                                        <li>
                                            <p className='descPara2'>
                                                any person or legal entity who is under a duty of confidentiality to Tor and has undertaken by agreement, or is subject to 
                                                professional confidentiality obligations, to keep such information confidential; and
                                            </p>
                                        </li>
                                        <li>
                                            <p className='descPara2'>
                                                any governmental or regulatory authority.
                                            </p>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p className='descPara2'>
                                        In addition to the above, and with your consent, Tor may use such information for a new purpose other than the purposes identified in this 
                                        Paragraph and transfer such information to a third party other than the persons identified in this Paragraph. In such a case, Tor will 
                                        separately notify you of the purpose(s) and provide you with the details of such transfer.
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p className='descPara2'>
                                Tor will retain any of your personal data collected pursuant to this Privacy Policy for as long as is necessary to fulfill the purposes described in 
                                Paragraph 1 above and any directly related purposes or as otherwise permitted by this Privacy Policy or this Site's Terms of Use. If Tor determines 
                                that retaining any of your personal data is required to comply with any applicable Laws or comply with Tor's legal obligations, to enforce this 
                                Privacy Policy or this Site's Terms of Use or any separate agreement or obligation between a user and Tor, to prevent fraud, to resolve disputes, to 
                                troubleshoot problems, to assist with any investigations and/or to take other actions as permitted by applicable Laws, Tor may retain such personal 
                                data for such time as is reasonably necessary for such purposes.
                            </p>
                        </li>
                        <li>
                            <p className='descPara2'>
                                It may be necessary for Tor to transfer certain of your personal data to places outside of Hong Kong in order to carry out the purposes (or directly 
                                related purposes) for which such personal data were collected. When your personal data is transferred outside Hong Kong, Tor will use commercially 
                                reasonable efforts to ensure that it is transferred, held and used in accordance with applicable Laws.
                            </p>
                        </li>
                        <li>
                            <p className='descPara2'>
                                Individuals have the right to check whether Tor holds any personal data relating to him or her and, if so, to obtain copies of such data. Individuals 
                                also may require Tor to correct any personal data relating to him or her that is inaccurate for the purpose for which it is being used. 
                            </p>
                            <ol type = 'a'>
                                <li>
                                    <p className='descPara2'>
                                        Tor reserves the right to impose any charge permitted by applicable Laws in connection with your access of, and copying of, any of your 
                                        personal data held by Tor. Enquire regarding this fee at the time you request access to, or copies of, any of your personal data held by Tor. 
                                    </p>
                                </li>
                                <li>
                                    <p className='descPara2'>
                                        You may exercise your right to correct your personal data held by Tor by sending written notice to the attention of the data protection 
                                        officer, Tor Investment Management (Hong Kong) Limited, 19/F, The Henley Building, 5 Queen’s Road Central, Central, Hong Kong, which notice 
                                        shall specify the data that you believe to be incorrect, the reason that you believe such data is incorrect and the applicable corrections. 
                                        You also must provide sufficient (as determined by Tor in its sole and absolute discretion) proof of identity to make corrections to your 
                                        personal data.
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p className='descPara2'>
                                Tor reserves the right to modify or supplement this Privacy Policy at any time in Tor's sole and absolute discretion by posting such changes to the 
                                Site or incorporating such changes into this Privacy Policy posted on the Site. Your use of the Site after such changes have been made shall 
                                constitute your acceptance of this Privacy Policy as so modified. 
                            </p>
                        </li>
                    </ol>
                </div>
            </div>
        );
    }
}

export default Privacy;
