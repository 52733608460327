import React, { Component } from 'react';

class Disclaimer extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return this.showPage();
    }

    showPage() {
        return (
            <div className='mt20 mb20 container'>
                <div className='row'>
                    <div className='mb20 cPointer'>
                        <a className='blueButton' href = '/'>Home</a>
                    </div>
                    
                    <h2 className="heading">General Disclaimer</h2>

                    <p className='descPara2'>
                        Under no circumstances should any information presented here be used or construed as an offer, or solicitation of any offer, or other form of invitation or 
                        inducement, to sell or buy any securities or other investments, or to provide advice regarding investments or a recommendation to purchase, sell or hold any 
                        security or other investment, or to pursue any investment style or strategy. There can be no assurance that any particular strategy or investment will prove 
                        profitable and the information contained herein should not be interpreted to provide a guarantee of future performance.  Investment strategies may be added, 
                        removed or adjusted, and/or the investment approach may be changed, at any time in Tor’s sole discretion and without any notice. There can be no guarantee 
                        that any strategy or approach employed by Tor will achieve its investment objectives or avoid losses.  Past performance is not indicative of future results.  
                    </p>

                    <p className='descPara2'>
                        This website may contain forward-looking statements. These statements are based on assumptions, analyses and expectations of Tor reasonably held in light of 
                        its experience and perception of historical trends, current conditions, expected future developments and other factors it reasonably believes appropriate 
                        under the circumstances. 
                    </p>

                    <p className='descPara2'>
                        All information on our website is subject to change at any time without notice.
                    </p>

                    <h2 className="heading">Terms of Use</h2>

                    <h1>Notice and Agreement</h1>

                    <p className='descPara2'>
                        By accessing this website (the “Site”), you acknowledge your agreement with and understanding of the following Terms of Use (“Terms”) pertaining to this 
                        Site including all content and material on it. These legal notices may be updated by us at any time. The Terms are between Tor Investment Management 
                        (Hong Kong) Limited, together with its affiliates (collectively, “Tor” or “we” or “our”) and you, or anyone authorized to access the Site on your behalf 
                        (collectively “you”).  Your continued use of the Site shall constitute your acceptance of these Terms at the time of access. If you do not agree to the Terms 
                        for any reason, please do not use this Site. These Terms do not govern the terms of any investment you may make or any other aspect of your relationship with 
                        Tor, beyond your use of or access to the Site.  
                    </p>

                    <h1>Ownership</h1>

                    <p className='descPara2'>
                        All content and material on this Site, such as graphics, logos, articles and other materials, is the property of Tor or others who have granted us permission 
                        to reproduce their material on this Site, and is protected by copyright and other laws. All trademarks and logos displayed on this Site are the property of 
                        their respective owners, who may or may not be affiliated with Tor. Nothing contained on this Site should be construed as granting, by implication, estoppel, 
                        or otherwise, any license or right to use any content or trademark displayed on this Site without the written permission of Tor or such other third party that 
                        may own the content or trademark displayed on this Site.  
                    </p>

                    <p className='descPara2'>
                        You shall not, nor shall you cause or permit any other party to: (i) modify, adapt, copy, alter, publish, print, disclose, download, translate or create 
                        derivative works of the Site; (ii) disable, disrupt, circumvent or otherwise interfere with any feature, functionality or control that prevents, restricts 
                        or otherwise governs access to, or use of, any aspect of the Site; (iii) build a product or service using similar ideas, features, functions or graphics of, 
                        or otherwise contained within, the Site; (iv) sublicense, resell, rent, lease, transfer or assign the Site or the use thereof, or to offer the Site on a 
                        time-share basis to any third party; (v) reverse engineer, decompile, decode or disassemble the Site (or any part thereof); (vi) attempt to derive the source 
                        code for the Site (or any part thereof) or attempt to gain access to any underlying code used to implement or deploy the Site, or (vii) otherwise exploit or 
                        use the Site in any way except as strictly set forth in these Terms.   
                    </p>

                    <h1>Informational Purposes Only; No Offer of Securities</h1>

                    <p className='descPara2'>
                        The information contained in this Site has been prepared solely for informational purposes and is not (and should not be construed as) an offer to sell or 
                        purchase or a solicitation of an offer to sell or purchase any securities or other investments (including an interest in any entity managed by Tor). Tor 
                        reserves the right (but is not obliged) to change, modify, add or remove portions of any content posted on the Site at any time without notice and without 
                        liability. Those who access and use this Site do so at their own initiative and are responsible for compliance with any laws or regulations applicable to them. 
                    </p>

                    <p className='descPara2'>
                        You acknowledge that the content of this Site is for general, informational purposes only and is not intended to constitute an offer to sell or buy any 
                        securities or other investments (including an interest in any entity managed by Tor) or promise to undertake or solicit business, and may not be relied upon 
                        in connection with any offer or sale of securities or other investments (including an interest in any entity managed by Tor). Any offer or solicitation will 
                        be made only through a final private placement memorandum, subscription agreement and other related documents with respect to a particular investment 
                        opportunity and will be subject to the terms and conditions contained in such documents, including the qualifications necessary to become an investor.
                    </p>

                    <p className='descPara2'>
                        We are not utilizing the Site to provide investment or other advice, and nothing on the Site is to be deemed a recommendation that you buy, sell or hold any 
                        security or other investments or that you pursue any investment style or strategy. If you would like investment, accounting, financial, tax or legal advice, 
                        you should consult with your own advisors with respect to your individual circumstances and needs.   
                    </p>

                    <p className='descPara2'>
                        This Site is not intended for, or use by, any person in any jurisdiction or country where such access or use would be contrary to local law or regulation. 
                        Tor makes no representations or warranties that the content of this Site is applicable or appropriate for access or use in any particular jurisdiction, 
                        or for any particular person.  
                    </p>

                    <h1>Privacy</h1>

                    <p className='descPara2'>
                        Our Site’s Privacy Policy forms part of these Terms and therefore the terms on which we provide and allow access to this Site. You acknowledge that we may 
                        process the information (including personal data) you provide if you use the Site in accordance with our Privacy Policy. 
                    </p>

                    <h1>Restrictions on Use</h1>

                    <p className='descPara2'>
                        Our prior permission is required for any commercial use of the content or materials on this Site, including the use of any links to the Site from any other 
                        websites. Such permission may be withheld at our sole and absolute discretion, and without reason.
                    </p>

                    <p className='descPara2'>
                        You agree not to use this Site or its content in violation or in facilitation of any violation of any applicable local, state, federal or international law, 
                        statute, regulation, code or ordinance, including, without limitation, regulations promulgated by or the rules of any national or other securities exchange. 
                        The permission to use this Site granted by the Terms automatically terminates if you breach any of the terms and conditions contained herein.
                    </p>

                    <p className='descPara2'>
                        This Site may contain links to third party websites. The Terms only apply to the Site and not any such linked third party websites. Our decision to link a 
                        third party website is not an endorsement of the contents of such linked third party website. If you access any linked third party website, you do so at your 
                        sole risk. 
                    </p>

                    <h1>No Warranties</h1>

                    <p className='descPara2'>
                        We have compiled this Site and its contents in good faith and while we use reasonable efforts to include accurate and up-to-date information on this Site, 
                        the Site is provided on an “as is” and “as available” basis with no warranties of any kind. We do not warrant that the information (including, without 
                        limitation, links to third party websites) on the Site is accurate, reliable, up to date or correct, that this Site will be available at any particular 
                        time or location, or that this Site is free of viruses or other harmful components.  
                    </p>

                    <p className='descPara2'>
                        Your use of this Site and use or reliance upon any of the content or materials on it is solely at your own risk. To the extent permissible by applicable law, 
                        Tor disclaims all warranties, express or implied, including any warranties of accuracy, non-infringement, merchantability and fitness for a particular purpose 
                        with respect to the Site and the materials and features available on or through the Site. You assume total responsibility for your use of the Site. 
                    </p>

                    <h1>Limitation of Liability</h1>

                    <p className='descPara2'>
                        To the extent permissible by applicable law, we are not responsible for any loss, damage, injury, or liability (including but not limited to special, indirect, 
                        incidental or consequential damages or any lost profits or lost opportunities) suffered by you or any third party that result from your use of (or inability 
                        to use) this Site, including any damage or injury caused by any inaccuracy or incompleteness of content of the Site, or any failure of performance, error, 
                        omission, interruption, defect, delay in operation, computer virus, line failure, or other computer malfunction. You understand and agree that your use of 
                        this Site is predicated upon your waiver of any right to sue us directly or to participate in a suit for any losses or damages resulting from your use of 
                        this Site.
                    </p>

                    <h1>Right to Modify</h1>

                    <p className='descPara2'>
                        Tor reserves the right to modify, suspend or terminate operation of or access to the Site, any portion of the Site or any service, product or feature 
                        available on the Site for any reason, at any time and without notice.  Each use of the Site constitutes your agreement to be bound by the then-current 
                        terms and conditions set forth in these Terms. 
                    </p>

                    <h1>Indemnification</h1>

                    <p className='descPara2'>
                        To the extent permissible by applicable law, you agree to indemnify, defend, protect and hold harmless Tor from and against any and all claims, costs, 
                        damages, demands, expenses, liabilities and losses of every kind and nature imposed upon or incurred by Tor directly or indirectly arising from (a) your 
                        use of and access to the Site, (b) your violation of any provision of these Terms, and (c) your violation of any third-party right, including without 
                        limitation any intellectual property or other proprietary right or injury of any third-party. 
                    </p>

                    <h1>Governing Law</h1>

                    <p className='descPara2'>
                        These Terms shall be governed by and interpreted in accordance with laws of the Hong Kong Special Administrative Region of the People’s Republic of China 
                        and we and you each submit irrevocably to the exclusive jurisdiction of the courts of the Hong Kong Special Administrative Region of the People’s Republic 
                        of China.  
                    </p>
                </div>
            </div>
        );
    }
}

export default Disclaimer;
