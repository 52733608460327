import React, { Component } from 'react';

const Patrik = require('../../images/Patrik.jpg');
const Chris = require('../../images/Chris.jpg');
const Jim = require('../../images/Jim.jpg');

class PatrikBio extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        document.title = "Patrik Esparr - TOR Investment Management"
        const el = document.querySelector("meta[name='description']");
        el.setAttribute('content', 'Patrik Esparr - TOR Investment Management')
    }

    render() {
        return this.showPage();
    }

    showPage() {
        return (
            <div className='mt20 container'>
                <div className='row'>
                    <div className='mb20 cPointer'>
                        <a className='blueButton' href = '/'>Home</a>
                    </div>
                    <div className='container'>
                            <section id="pedsparr">
                                <div className='row'>
                                    <div className='col-xl-5 col-lg-5 col-md-12 col-12 border-right-white ptb70 height-adjust'>
                                        
                                        <img src={Patrik} alt="Patrik Edsparr Headshot" class="headshot-image"/>
                                    </div>
                                    <div className='col-xl-7 col-lg-7 col-md-12 col-12' style={{ paddingLeft: "5%" }}>
                                        <h2 className='heading'>
                                            Patrik Edsparr <br />
                                        </h2>
                                        <h3 className='cBlue fs17'>Chief Investment Officer, Co-Founder</h3>
                                        <p className='descPara'>
                                            Patrik Edsparr serves as the Chief Investment Officer and is a founding partner of Tor Investment Management. This prestigious firm stands 
                                            out as an independent authority in alternative credit management, with a keen focus on the credit markets of the Asia-Pacific region. 
                                            It boasts an impressive portfolio exceeding $2.3 billion in managed assets.

                                            <br /><br />

                                            Boasting more than three decades of hands-on experience in finance and investment management, Patrik brings a wealth of knowledge and 
                                            expertise to his role. As the Chief Investment Officer, he plays a pivotal role in the strategic oversight of portfolio construction 
                                            and risk management. He also presides over the Investment Committee, guiding its decision-making processes.
                                            
                                            <br /><br />

                                            Before co-founding Tor Investment Management, Patrik dedicated a significant portion of his career to JP Morgan Chase & Co. There, he was 
                                            instrumental in establishing and leading the Proprietary Positioning and Principal Investing Division. His tenure at JP Morgan was marked 
                                            by his management of investments in distressed assets, mezzanine financing, and special situations across various global markets including 
                                            North America, Europe, and Asia. Patrik's leadership was recognized through his positions on the Executive Committee and the Investment 
                                            Bank's Management Committee, in addition to his roles on the Capital and Risk Committees. Notably, he served as the Co-head of North 
                                            American Markets from 2004 to 2006 and as the Global Co-head of Fixed Income from 2006 to 2008.

                                            <br/><br/>

                                            In 2008, Patrik transitioned to Citadel Investment Group, where he was appointed the Global Head of Fixed Income and CEO of Citadel Europe. 
                                            His expertise was further acknowledged from 2010 to 2011 when he became a Visiting Fellow at the Bank of England, offering his counsel on 
                                            various issues related to market structure and capital. Patrik's academic credentials are equally impressive. He earned a PhD in Financial 
                                            Economics from MIT as a Fulbright Scholar. He also holds an MSc in Applied Mathematics from the Royal Institute of Technology, an MBA 
                                            from the Stockholm School of Economics, and a BA from Uppsala University.

                                            <br/><br/>

                                            His leadership qualities were honed in the Swedish Army, where he served as a lieutenant at the Swedish Defence Language School. Beyond 
                                            his military and academic accomplishments, Patrik has contributed his expertise as a Non-Executive Director of MTS S.p.A. and Nomura 
                                            International, including a tenure as chair of the Board Risk Committee.
                                        </p>
                                    </div>
                                </div>
                            </section>
                        </div>
                </div>
            </div>
        );
    }
}

export default PatrikBio;
